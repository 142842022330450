/**Created by liaoyingchao on 11/24/22.*/

<template>
  <div class="product page-css list-css" v-loading="pageLoading">
    <div class="top-tools">
      <el-form ref="RefFilterForm" :model="form" label-width="90px">
        <div class="filter-items">
          <el-form-item class="filter-item" label="商品名称">
            <el-input v-model="form.productName" clearable placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item class="filter-item" label="商品分类">
            <el-select v-model="form.categoryId" placeholder="请选择商品分类" style="width: 100%;" clearable>
              <el-option
                      v-for="item in categoryOptions"
                      :key="item.categoryId"
                      :label="item.categoryName"
                      :value="item.categoryId"
              />
            </el-select>
          </el-form-item>
          <div class="btns-div">
            <el-button type="primary" :icon="Search" @click="filterEvent">查询</el-button>
            <el-button :icon="Plus" @click="newEvent">新建商品</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="full-container">
      <TableContainer ref="TabelContainer" :listApiFunction="getList">
        <template #default="tbData">
          <el-table
                  :height="tbData.data.tableHeight"
                  :data="tbData.data.tableData"
                  border
                  stripe
                  header-row-class-name="theader"
                  style="width: 100%">
            <el-table-column
                    prop="productName"
                    label="商品名称"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    prop="productPicture"
                    label="商品图片"
                    min-width="100">
              <template #default="scope">
                <div v-if="scope.row.productPicture">
                  <img style="display: block;width: 50px;height: 50px;" :src="scope.row.productPicture">
                </div>
              </template>
            </el-table-column>
            <el-table-column
                    prop="productDescription"
                    label="商品描述"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    prop="productUnit"
                    label="单位"
                    min-width="80">
            </el-table-column>
            <el-table-column
                    prop="productPrice"
                    label="原价(元)"
                    min-width="100">
            </el-table-column>
            <!--<el-table-column-->
                    <!--prop="product"-->
                    <!--label="商品分类"-->
                    <!--min-width="100">-->
            <!--</el-table-column>-->
            <el-table-column
                    prop="state"
                    label="状态"
                    min-width="100">
              <template #default="scope">
                {{ scope.row.deleteFlag == 1 ? '已删除' : '正常' }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="createdTime"
                    label="创建时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.createdTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="updateTime"
                    label="修改时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.updateTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="160">
              <template #default="scope">
                <el-button @click="editEvent(scope.row)" type="primary" text>编辑</el-button>
                <el-button @click="deleteEvent(scope.row)" type="danger" text>删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </TableContainer>
    </div>
    <el-dialog
            class="center-dialog"
            v-model="dialogVisible"
            :title="editTitle"
            width="60%"
            destroy-on-close
    >
      <div class="dialog-content">
        <el-form ref="Ref_editForm" :model="editForm" :rules="formRules" label-width="120px">
          <el-form-item label="商品名称" prop="name">
            <el-input v-model="editForm.name" placeholder="请输入商品名称" maxlength="30"/>
          </el-form-item>
          <el-form-item label="商品价格(元)" prop="price">
            <el-input v-model="editForm.price" placeholder="请输入商品价格" />
          </el-form-item>
          <el-form-item label="商品图片" prop="picture">
            <el-upload
                    class="avatar-uploader"
                    action="/api/files/upload"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
            >
              <img v-if="editForm.picture" :src="editForm.picture" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="商品分类" prop="categoryId">
            <el-select v-model="editForm.categoryId" placeholder="请选择商品分类" style="width: 100%;">
              <el-option v-for="item in categoryOptions"
                         :key="item.categoryId"
                         :label="item.categoryName"
                         :value="item.categoryId"/>
            </el-select>
          </el-form-item>
          <el-form-item label="商品单位" prop="unit">
            <el-input v-model="editForm.unit" placeholder="请输入商品单位"/>
          </el-form-item>
          <el-form-item label="商品描述">
            <el-input type="textarea" v-model="editForm.description" placeholder="请输入商品描述" maxlength="100"/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveEvent">保存</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import {ref, reactive} from 'vue'
  import {
    Search,
    Plus,
  } from '@element-plus/icons-vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import TableContainer from '@/components/table-container/index'
  import FormCheck from "@/utils/formCheck";
  import categoryApi from '@/api/category'
  import productApi from '@/api/product'
  import {timeYYYY_MM_DD_HH_mm_ss} from '@/utils/time-format.js'

  const pageLoading = ref(false)
  const RefFilterForm = ref(null)

  const form = reactive({
    productName: '',
    categoryId: ''
  })

  function getList(data) {
    return productApi.list(data);
  }

  const categoryOptions = ref([])

  function getCategoryList() {
    categoryApi.list({
      pageNumber: 1,
      pageSize: 100
    }).then(res => {
      if (res.code == 0) {
        categoryOptions.value = res.data || [];
      } else {
        ElMessage.error(res.msg);
      }
    })
  }

  getCategoryList();

  const TabelContainer = ref(null)

  function filterEvent() {
    TabelContainer.value.filterEvent(form)
  }

  let dialogVisible = ref(false)
  const editTitle = ref('')

  const editForm = reactive({
    id: '',
    name: '',
    price: '',
    picture: '',
    categoryId: '',
    description: '',
    unit: '份',
  })

  const formRules = {
    name: FormCheck.required('请输入商品名称'),
    price: [FormCheck.required('请输入商品价格'), FormCheck.justPrice('请输入正确的价格')],
    picture: FormCheck.required('请选择商品图片'),
    categoryId: FormCheck.required('请选择商品分类'),
    unit: FormCheck.required('请选择单位'),
    sortValue: FormCheck.justPositiveInt(),
  }

  const Ref_editForm = ref(null)

  function newEvent() {
    dialogVisible.value = true
    editTitle.value = '新建商品'

    editForm.id = ''
    editForm.name = ''
    editForm.price = ''
    editForm.picture = ''
    editForm.categoryId = ''
    editForm.description = ''
    editForm.unit = '份'
  }

  function saveEvent() {
    Ref_editForm.value.validate((valid, fields) => {
      if (valid) {
        console.log('submit!')
        if (editForm.id == '') {
          pageLoading.value = true
          productApi.create({
            product: {
              productName: editForm.name,
              productDescription: editForm.description,
              productPrice: editForm.price,
              productPicture: editForm.picture,
              categoryId: editForm.categoryId,
              productUnit: editForm.unit,
            }
          }).then(res => {
            if (res.code == 0) {
              dialogVisible.value = false
              filterEvent();
            } else {
              ElMessage.error(res.msg);
            }
          }).finally(() => {
            pageLoading.value = false
          })
        } else {
          pageLoading.value = true
          productApi.update({
            product: {
              productId: editForm.id,
              productName: editForm.name,
              productDescription: editForm.description,
              productPrice: editForm.price,
              productPicture: editForm.picture,
              categoryId: editForm.categoryId,
              productUnit: editForm.unit,
            }
          }).then(res => {
            if (res.code == 0) {
              dialogVisible.value = false
              // filterEvent();
              TabelContainer.value.loadData()
            } else {
              ElMessage.error(res.msg);
            }
          }).finally(() => {
            pageLoading.value = false
          })
        }
      } else {
        console.log('error submit!', fields)
      }
    })
  }

  function handleAvatarSuccess(response, uploadFile) {
    console.log(response);
    if (response.code == 0) {
      let data = response.data;
      editForm.picture = data.filePath || '';
    } else {
      ElMessage.error(msg);
    }
  }

  function beforeAvatarUpload(rawFile) {
    console.log(rawFile)
    if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
      ElMessage.error('请选择图片！')
      return false
    } else if (rawFile.size / 1024 > 500) {
      ElMessage.error('商品图片不能超过500kb!')
      return false
    }
    return true
  }

  function editEvent(item) {
    dialogVisible.value = true
    editTitle.value = '编辑商品'

    editForm.id = item.productId
    editForm.name = item.productName
    editForm.price = item.productPrice
    editForm.picture = item.productPicture
    editForm.categoryId = item.categoryId
    editForm.description = item.productDescription
    editForm.unit = item.productUnit
  }

  function deleteEvent(item) {
    ElMessageBox.confirm('您确定需要删除该商品吗？', '删除提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      pageLoading.value = true
      productApi.delete({
        productId: item.productId
      }).then(res => {
        if (res.code == 0) {
          // filterEvent();
          ElMessage.success("删除成功！");
          TabelContainer.value.loadData()
        } else {
          ElMessage.error(res.msg);
        }
      }).finally(() => {
        pageLoading.value = false
      })
    }).catch(() => {

    })
  }
</script>

<style lang="stylus" scoped type="text/stylus">
  .product {
    >>>.dialog-content {
      max-height 65vh;
      overflow-y auto;
      padding-right 40px;

      .avatar-uploader .avatar {
        width: 178px;
        height: 178px;
        display: block;
      }

      .avatar-uploader .el-upload {
        border: 1px dashed var(--el-border-color);
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: var(--el-transition-duration-fast);
      }

      .avatar-uploader .el-upload:hover {
        border-color: var(--el-color-primary);
      }

      .el-icon.avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        text-align: center;
      }
    }
  }
</style>